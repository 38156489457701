import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';

@Component({
  selector: 'app-website-images-upload',
  templateUrl: './website-images-upload.component.html',
  styleUrls: ['./website-images-upload.component.css']
})
export class WebsiteImagesUploadComponent implements OnInit {
  Token: string = localStorage.getItem('auth_token');
  str: string;
  ImageResult1: string;
  imageUpload: FormGroup;
  SpResult: any;
  @ViewChild('fileInput') fileInput;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' } });
  constructor(private fb: FormBuilder, private mainservice: MainService) { }
  ngOnInit(): void {
    this.imageUpload = this.fb.group({
      ImageId: [''],
      ImagePath: [''],
      ImageName: [''],
    });
    //file Uploader
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.imageUpload.value.FileName = this.ImageResult1;
      this.imageUpload.controls['ImagePath'].setValue(this.ImageResult1);
    }
    //End file Uploader

  }
  Insert() {
    var MoObj: any = {}
    MoObj.Pid = "23b6d3df-81a1-44fb-a685-07fbeae5abd0";
    MoObj.ImgPath = this.imageUpload.value.ImagePath;
    MoObj.ImgName = this.imageUpload.value.ImageName;
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  List() {
    var MoObj: any = {}
    MoObj.Pid = "6dc5baef-7aa0-4b4a-8729-2fe36b461f44";
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table
    });
  }
  Reset(){
    this.imageUpload.reset();
  }
}
