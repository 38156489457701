import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AchievementComponent } from './achievement/achievement.component';
import { CommitteesComponent } from './committees/committees.component';
import { ContactComponent } from './contact/contact.component';
import { EventComponent } from './event/event.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { NewsComponent } from './news/news.component';
import { NewspaperComponent } from './newspaper/newspaper.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { PhotoComponent } from './photo/photo.component';
import { RegistrationComponent } from './registration/registration.component';
import { VideoComponent } from './video/video.component';
const routes: Routes = [{
  path: '', component: LayoutComponent,
  children: [
    { path: '', component: MainComponent },
    {path:'main', component:MainComponent},
    { path: 'login', component: LoginComponent },
    {
      path:'event',component:EventComponent
    },
     {
       path:'contact', component:ContactComponent
     },
     {
      path:'about', component:AboutComponent
    },
    {
      path:'ourteam', component:OurteamComponent
    },
    {
      path:'registration', component:RegistrationComponent
    },
    {
      path:'news', component:NewsComponent
    },
    {
      path:'achievement', component:AchievementComponent
    },
    {
      path:'video', component:VideoComponent
    },
    {
      path:'photo', component:PhotoComponent
    },
    {
      path:'committees', component:CommitteesComponent
    },
    {
      path:'newspaper', component:NewspaperComponent
    },
  ]
}
];
const routerOptions:ExtraOptions={
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
