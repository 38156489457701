import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-ourteam',
  templateUrl: './ourteam.component.html',
  styleUrls: ['./ourteam.component.css']
})
export class OurteamComponent implements OnInit {
  SpResult:any;
  constructor( private mainservice: MainService, private router:Router) { }
  ngOnInit(): void {
  this.teamMemberList();
  }
  teamMemberList() {
   var MoObj: any = {}
    MoObj.Pid = "53bc0451-206d-40a7-9549-8c0071809877";
    MoObj.PkWebSite_TeamMember=0;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
}
