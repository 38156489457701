<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-bg">
        <form [formGroup]="employeeForm">
          <h2 class="text-center">News</h2>
          <br>
          <div class="form-group">
            <label for="uname"><b>News ID</b></label>
            <input type="text" class="form-control" placeholder="ImageId" formControlName="NewsId">
          </div>
          <div class="form-group">
            <label for="uname"><b> News type</b></label>
            <select class="form-control" id="exampleFormControlSelect1" formControlName="Newstype">
              <option value="" selected disabled>Please Select</option>
              <option *ngFor="let item of updatespresult" value="{{item.pkWebSite_NewsType}}">{{item.typeDec}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="uname"><b>News Decription</b></label>
            <ck-editor name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"
              formControlName="NewsDec"></ck-editor>
          </div>
          <div class="form-group">
            <label for="uname"><b>From Date</b></label>
            <input type="date" class="form-control" formControlName="Fromdate" id="datepicker">
          </div>
          <div class="form-group">
            <label for="uname"><b>To Date</b></label>
            <input id="requestdate" type="date" class="form-control" formControlName="Todate" />
          </div>
          <div class="form-group">
            <label for="uname"><b>file Upload</b></label>
            <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader" id="fileInput"
              formControlName="ImagePath" />
          </div>
          <div class="align-center">
            <button type="submit" class="btn btn-primary" (click)="Insert()">Insert</button>&nbsp;
            <button type="submit" class="btn btn-primary" (click)=" Update()">Update</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary" (click)="Delete()">Delete</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary" (click)="List()">List</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary">Exit</button>&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </form>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container">
        <div class="table-responsive">
          <table class="table" id="tblList">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fk</th>
                <th>Path</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cmp of SpResult">
                <td (click)="imageDelete(cmp.pkWebSite_NewsImg)">{{cmp.pkWebSite_NewsImg}}</td>
                <td>{{cmp.fkWebSite_NewsType}}</td>
                <td><img src="{{cmp.folderPath}}"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<div class="container">
  <div class="table-responsive">
    <table class="table" id="tblList">
      <tbody *ngFor="let cmp of news;">
        <tr>
          <th>ID</th>
        </tr>
        <tr>
          <td (click)="RowSelected(cmp)">{{cmp.pkWebSite_News}}</td>
        </tr>
        <tr>
          <th>News Type</th>
        </tr>
        <tr>
          <td>{{cmp.typeDec}}</td>
        </tr>
        <tr>
          <th>News Description</th>
        </tr>
        <tr>
          <td innerHTML="{{cmp.newsDec}}">{{cmp.newsDec}}</td>
        </tr>
        <tr>
          <th>FromDate</th>
        </tr>
        <tr>
          <td>{{cmp.fromDate}}</td>
        </tr>
        <tr>
          <th>ToDate</th>
        </tr>
        <tr>
          <td>{{cmp.toDate}}</td>
        </tr>
        <br>
        <br>
      </tbody>
    </table>
  </div>
</div>