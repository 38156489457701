<!-- Footer Start -->
<div class="container-fluid bg-dark pt-5 px-sm-3 px-md-5 mt-5">
    <div class="row py-4">
        <div class="col-lg-4 col-md-6 mb-5">
            <h5 class="mb-4 text-white text-uppercase font-weight-bold">Get In Touch</h5>
            <p class="font-weight-medium FontColor"><i class="fa fa-map-marker-alt mr-2"></i>Plot No.62, Hanuman Nagar, Medical Square, Nagpur </p>
            <p class="font-weight-medium FontColor"><i class="fa fa-phone-alt mr-2"></i> 09371247344, 09552011005</p>
            <p class="font-weight-medium FontColor"><i class="fa fa-envelope mr-2"></i>manavadhikar2012@gmail.com</p>
            <h6 class="mt-4 mb-3 text-white text-uppercase font-weight-bold">Follow Us</h6>
            <div class="d-flex justify-content-start icons">
                <a class="btn btn-lg btn-secondary BorderRadius btn-lg-square mr-2" style="border-color:#31404B;" href="#"><i class="fab fa-twitter"></i></a>
                <a class="btn btn-lg btn-secondary BorderRadius btn-lg-square mr-2" style="border-color:#31404B;" href="#"><i class="fab fa-facebook-f"></i></a>
                <a class="btn btn-lg btn-secondary BorderRadius btn-lg-square mr-2" style="border-color:#31404B;" href="#"><i class="fab fa-linkedin-in"></i></a>
                <a class="btn btn-lg btn-secondary BorderRadius btn-lg-square mr-2" style="border-color:#31404B;" href="#"><i class="fab fa-instagram"></i></a>
                <a class="btn btn-lg btn-secondary BorderRadius btn-lg-square" style="border-color:#31404B;" href="#"><i class="fab fa-youtube"></i></a>
            </div>
            <br>
            <a href="https://www.freecounterstat.com" title="page counter"><img src="https://counter10.stat.ovh/private/freecounterstat.php?c=8nek4qs8sygdthb44n8dsxaj6n3ft6fe" title="page counter" alt="page counter"></a>
        </div>
        <div class="col-lg-4 col-md-6 mb-5">
            <h5 class="mb-4 text-white text-uppercase font-weight-bold">Important Links </h5>
            <div class="m-n1">
               &nbsp;<a class="small text-uppercase font-weight-medium FontColor MarqueeTag" routerLink="/main" onClick="window.scrollTo(0,0)">Home</a><br>
               &nbsp;<a class="small text-uppercase font-weight-medium FontColor MarqueeTag" routerLink="/about" onClick="window.scrollTo(0,0)">Who We Are</a><br>
               &nbsp;<a class="small text-uppercase font-weight-medium FontColor MarqueeTag" routerLink="/news" onClick="window.scrollTo(0,0)">News</a><br>
               &nbsp;<a class="small text-uppercase font-weight-medium FontColor MarqueeTag" routerLink="/event" onClick="window.scrollTo(0,0)">Event</a><br>
               &nbsp;<a class="small text-uppercase font-weight-medium FontColor MarqueeTag" routerLink="/newspaper" onClick="window.scrollTo(0,0)">News Paper </a><br>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-5">
            <h5 class="mb-4 text-white text-uppercase font-weight-bold">Photos</h5>
            <div class="row">
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/1.jpg" alt=""></a>
                </div>
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/2.jpg" alt=""></a>
                </div>
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/3.jpg" alt=""></a>
                </div>
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/4.jpg" alt=""></a>
                </div>
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/5.jpg" alt=""></a>
                </div>
                <div class="col-4 mb-3">
                    <a href=""><img class="w-100" src="assets/img/6.jpg" alt=""></a>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="container-fluid py-4 px-sm-3 px-md-5 FontColor" style="background: #111111;">
    <p class="m-0 text-center ">&copy; <a href="#" style="color: #FFCC00 ; text-decoration: none;">Kendriya Manavadhikar</a>. All Rights Reserved. Design & Developed By:- <a href="https://www.oceansofttech.net" style="color: #FFCC00 ;text-decoration: none;" target="blank">Ocean Software Technologies</a></p>
  </div>
  <!-- Footer End -->
  <!-- Back to Top -->
  <a href="#" class="btn btn-primary btn-square back-to-top bg-primary" style="border-color: #FFCC00 ; color:#111111"><i class="fa fa-arrow-up" style="right:10"></i></a>