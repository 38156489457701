<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">

                <p class="login-card-description">Website Image Upload</p>
                <form [formGroup]="imageUpload">


                    <div class="form-group">
                        <label for="formGroupExampleInput">Image ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="ImageId">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Image Name:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="ImageName">
                    </div>

                    <div class="form-group row">
                        <label for="formGroupExampleInput">Image:</label>
                        <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader"
                            (change)="uploader.uploadAll()" id="fileInput" />

                    </div>
                    <div class="form-group">
                        <label for="uname"><b>Image Url</b></label>
                        <input type="text" class="form-control" placeholder="UrlPath" formControlName="ImagePath">
                    </div>


                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">

                    <input name="Update" id="login" disabled class="btn btn-sm mb-4 category-btn" type="button"
                        value="Update">
                    <input name="Delete" id="login" disabled class="btn btn-sm mb-4 category-btn" type="button"
                        value="Delete">

                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>

            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">

            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Image
                                    </th>
                                    <th>
                                        Image Name
                                    </th>
                                </thead>
                                <tbody *ngFor="let imgName of SpResult">
                                    <tr>
                                        <td>
                                            {{imgName.pk_WebSite_ImagesUpload}}
                                        </td>
                                        <td><img src="{{imgName.folderPath}}"></td>
                                        <td>{{imgName.imgName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>