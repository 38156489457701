import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.css']
})
export class ClientLogoComponent implements OnInit {
  SpResult:any;
  constructor( private mainservice: MainService,private router:Router) { }
  ngOnInit(): void {
   this.PublicList();
  }
  PublicList() {
    var MoObj: any = {}
    MoObj.Pid = "1717ecfe-f0fb-4d52-a633-595c2f0abdbd";
    MoObj.Pk_WebSite_ClientLogoImg=0;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
}
