<div class="row" style="padding-bottom: 20px;">
<div class="col-12">
    <div class="section-title">
        <h4 class="m-0 text-uppercase font-weight-bold">Video By:-KENDRIYA MANAVADHIKAR</h4>
        <a class="text-secondary font-weight-medium text-decoration-none" routerLink="/video">View All</a>
    </div>
</div>
<div class="container-fluid py-3 mb-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <marquee onmouseover="this.stop();" onmouseleave="this.start();">
                    <ng-container *ngFor="let listitem of list">
                        <iframe width="315" height="315" [src]="getEmbedUrl(listitem.videoPath)" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>&nbsp; &nbsp;     
                     </ng-container>
                </marquee>
            </div>
        </div>
    </div>
</div>
</div>
