<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">PDFS</p>
                <form [formGroup]="Pdfform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">PDF ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="pdfID">
                    </div>
                    <div class="form-group">
                        <label for="sel1">Select Media Type:</label>
                        <select class="form-control" id="sel1" (change)="navigateTo($event.target.value)" formControlName="Pdfcategory">
                          <option>--Select Meadia--</option>
                          <option value="Daily">Daily</option>
                          <option value="Weakly">Weakly</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="uname"><b>Pdf Title</b></label>
                        <input type="text" class="form-control" placeholder="pdf Title" formControlName="PdfTitle">
                    </div>
                    <div class="form-group row">
                        <label for="formGroupExampleInput">PDF Upload:</label>
                        <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader"
                         formControlName="pdfPath" id="fileInput"/>
                    </div>
                    <div class="form-group">
                        <label for="uname"><b>Date</b></label>
                        <input type="date" class="form-control" placeholder="" formControlName="insertedDate">
                    </div>
                    <div class="form-group">
                        <label for="uname"><b>Inserted By</b></label>
                        <input type="text" class="form-control" placeholder="Inserted By" formControlName="insertedBy">
                    </div>
                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">
                    <input name="Update" id="login" disabled class="btn btn-sm mb-4 category-btn" type="button"
                        value="Update">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto;">
                <div class="card" style="width:100%;">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>Id</th>
                                    <th>PDF</th>
                                    <th>PDF Title</th>
                                    <th>Date</th>
                                    <th>Inserted By</th>
                                </thead>
                                <tbody >
                                    <tr *ngFor="let pdf of photogallery">
                                        <td (click)="RowSelected(pdf)" onClick="window.scrollTo(0,0)">
                                            {{pdf.pkWebSite_PDFPath}}
                                        </td>
                                        <td> <iframe [src]="getEmbedUrl(pdf.folderPath)" width="510" height="432" ></iframe> </td>
                                        <td innerHTML="{{pdf.description}}">{{pdf.description}}</td>
                                        <td>{{pdf.insertedDate | date}}</td>
                                        <td>{{pdf.insertedBy}}</td>
                                        <td>{{pdf.category}}</td>
                                       </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>