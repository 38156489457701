import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-website-video-gallery-header',
  templateUrl: './website-video-gallery-header.component.html',
  styleUrls: ['./website-video-gallery-header.component.css']
})
export class WebsiteVideoGalleryHeaderComponent implements OnInit {
  Galleryheadingform: FormGroup;
  SpResult: any;
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.Galleryheadingform = this.fb.group({
      GalleryHeaderID: [''],
      GalleryHeader: [''],
    });
  }
  Insert() {
    const MoObj: any = {};
    MoObj.Pid = '932efdf3-df72-4ff8-b47c-b2088d0c4651';
    MoObj.HeaderDec = this.Galleryheadingform.value.GalleryHeader;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }

  Delete() {
    const MoObj: any = {};
    MoObj.Pid = 'b279882f-2bfa-4a7c-8cd3-94577fdb94f0';
    MoObj.PkWebSite_VideoGalleryHeader = this.Galleryheadingform.value.GalleryHeaderID;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }

  List() {

    const MoObj: any = {};
    MoObj.Pid = '08fab527-8527-4c50-97c8-2694ca9a6b0a';
    MoObj.PkWebSite_VideoGalleryHeader = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  RowSelected(u: any) {
    this.Galleryheadingform.controls['GalleryHeaderID'].setValue(u.pkWebSite_VideoGalleryHeader);
    this.Galleryheadingform.controls['GalleryHeader'].setValue(u.headerDec);
  }
  Reset(){
    this.Galleryheadingform.reset();
  }

}
