<div class="container-fluid d-none d-lg-block">
    <div class="row align-items-center bg-dark px-lg-5">
        <div class="col-lg-9">
            <nav class="navbar navbar-expand-sm bg-dark p-0">
                <ul class="navbar-nav ml-n2">
                    <li class="nav-item border-right border-secondary">
                        <a class="nav-link small FontColor" href="#">{{date |date}}</a>
                    </li>
                    <li class="nav-item border-right border-secondary">
                        <a class="nav-link small FontColor" routerLink='/contact'>Contact</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link small FontColor" routerLink='/login'>Login</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-lg-3 text-right d-none d-md-block">
            <nav class="navbar navbar-expand-sm bg-dark p-0">
                <ul class="navbar-nav ml-auto mr-n2">
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-twitter"></small></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-facebook-f"></small></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-linkedin-in"></small></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-instagram"></small></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-google-plus-g"></small></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link FontColor" href="#"><small class="fab fa-youtube"></small></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="row align-items-center bg-white py-3 px-lg-5">
        <div class="col-lg-4">
            <a routerLink="main" fragment="" class="navbar-brand p-0 d-none d-lg-block">
                <img src="assets/img/logo.png"> <img src="assets/img/logo2.png" width="250px" class="ml-5">
            </a>
        </div>
        <div class="col-lg-8 text-center text-lg-right">
            <a routerLink='/registration' fragment=""><img class="img-fluid" src="assets/img/joinnow.gif" width="300px" alt="">
            </a>
        </div>
    </div>
</div>
<!-- Navbar Start -->
<div class="container-fluid p-0">
    <nav class="navbar navbar-expand-lg bg-dark navbar-dark py-2 py-lg-0 px-lg-5">
        <a routerLink='/main' fragment="" class="navbar-brand d-block d-lg-none">
            <img src="assets/img/logo.png ">
        </a>
        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-between px-0 px-lg-3" id="navbarCollapse">
            <div class="navbar-nav mr-auto py-0">
                <a routerLink='/main' fragment="hero" class="nav-item nav-link active">Home</a>
                <a routerLink='/newspaper' fragment="newspaper" class="nav-item nav-link">News Paper</a>
                <a routerLink='/about' fragment="about" class="nav-item nav-link">Who We Are</a>
                <a routerLink='/achievement' fragment="achievement" class="nav-item nav-link">Achievements</a>
                <a routerLink='/registration' fragment="registration" class="nav-item nav-link">Registration</a>
                <a routerLink='/news' fragment="news" class="nav-item nav-link">News</a>
                <a routerLink='/event' fragment="event" class="nav-item nav-link">Event</a>
                <a routerLink='/committees' fragment="committees" class="nav-item nav-link">Committees</a>
                <a routerLink='/ourteam' fragment="ourteam" class="nav-item nav-link">Pan India Team</a>
                <a routerLink='/photo' fragment="photo" class="nav-item nav-link">Photo Gallery</a>
                <a routerLink='/video' fragment="video" class="nav-item nav-link">Video Gallery</a>
            </div>
        </div>
    </nav>
</div>
<!-- Navbar End -->