<section id="team" class="pb-5" id="ourteam">
    <div class="container">
        <h5 class="section-title h1" style="color:#000!important;">OUR TEAM</h5>
        <div class="row">
            <!-- Team member -->
            <ng-container *ngFor="let team of SpResult">
                <div class="col-xs-12 col-sm-6 col-md-4">
                    <div class="image-flip">
                        <div class="mainflip flip-0">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <img src="{{team.folderPath}}" alt="card image">
                                        <h4 innerHTML="{{team.memberDec}}">{{team.memberDec}}</h4>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>