<div class="container" id="photo">
  <div class="row">
    <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1 class="gallery-title pt-5">Photo gallery</h1>
    </div>
    <div>
      <ng-container *ngFor="let drop of table">
        <a><button class="btn btn-default filter-button mr-2 btn2"
            (click)=openlist(drop.pkWebSite_PhotoGalleryHeader)>{{drop.headerDec}}</button>
        </a>
      </ng-container>
    </div>
    <br/>
    <div id="containerselected">
      <div id="containerAll">
        <ng-container *ngFor="let listitem of table2">
          <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
            <a href="{{listitem.images}}" (click)=openlistt(listitem.images) data-toggle="modal"
              data-target="#exampleModal"><img src="{{listitem.folderPath}}" class="img-fluid w-100 mb-3"></a>
          </div>
        </ng-container>
      </div>
      <ng-container *ngFor="let listitem of list">
        <ng-container *ngIf="selectedList === listitem.fkWebSite_PhotoGalleryHeder">
          <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
            <a href="{{listitem.images}}" (click)=openlistt(listitem.images) data-toggle="modal"
              data-target="#exampleModal"><img src="{{listitem.images}}" class="img-fluid w-100 mb-3"></a>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <ng-container *ngFor="let dropp of list">
      <ng-container *ngIf="selectedListimg===dropp.images">
        <div class="gallery_product col-lg-12 col-md-12 col-sm-12 col-xs-12 filter sprinkle">
          <img src="{{dropp.images}}" class="img-fluid w-100 mb-3">
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>