import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-website-team-member',
  templateUrl: './website-team-member.component.html',
  styleUrls: ['./website-team-member.component.css']
})
export class WebsiteTeamMemberComponent implements OnInit {
  public editorValue: string = '';
  UpdateSpResult: any;
  teammemberform: FormGroup;
  SpResult: any;
  ImageResult:any;
  photogallery: any;
  str: string;
  ImageResult1: string;
  discription:any;
  data:any;
  dataa:any;
  Token: string = localStorage.getItem('auth_token');
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' } });
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.teammemberform = this.fb.group({
      memberId: [''],
      MemberDecription: [''],
      MemberPath: [''],
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.teammemberform.value.FileName = this.ImageResult1; 
    } 
  }
  async Insert() {
    const MoObj: any = {};
    MoObj.Pid = 'f7b143e8-1074-4a3e-8ffd-8535bbdbc7cc';
    MoObj.MemberDec = this.teammemberform.value.MemberDecription;
    localStorage.setItem('teamdescription',MoObj.MemberDec);
    this.data=localStorage.getItem('teamdescription');
    if(this.data!==""){
       this.dataa = this.uploader.uploadAll();
       let handlePromise = new Promise((resolve, reject) => {
         setTimeout(() => {
           resolve(this.dataa);
         }, 5000)
       })
       await handlePromise
       MoObj.MemberPath = this.teammemberform.value.FileName;
      }
    this.mainService.OneMethod(MoObj).then(value =>{
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = 'f012bc89-cd72-4710-baf1-213d89b84efe';
    MoObj.PkWebSite_TeamMember = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  Delete() {
    const MoObj: any = {};
    MoObj.Pid = 'd36c65e5-23ef-46cd-a1de-8731d3b4b39c';
    MoObj.PkWebSite_TeamMember = this.teammemberform.value.memberId;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }
  Update() {
    const MoObj: any = {};
    MoObj.Pid = '74340d28-a0d5-4536-9d0d-454c28203283';
    MoObj.PkWebSite_TeamMember = this.teammemberform.value.memberId;
    MoObj.MemberDec = this.teammemberform.value.MemberDecription;
    MoObj.MemberPath = this.teammemberform.value.MemberPath;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
    });
    this.List();
  }
  RowSelected(u: any) {
    this.teammemberform.controls['memberId'].setValue(u.pkWebSite_TeamMember);
    this.teammemberform.controls['MemberDecription'].setValue(u.memberDec);
  }
  Reset(){
    this.teammemberform.reset();
  }
}