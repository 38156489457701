<div class="row">
    <div class="col-12">
        <div class="section-title">
            <h4 class="m-0 text-uppercase font-weight-bold">Latest News</h4>
            <a class="text-secondary font-weight-medium text-decoration-none" routerLink="/news">View All</a>
        </div>
    </div>
    <ng-container *ngFor="let item of eventList | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="col-lg-10">
            <div class="position-relative mb-3">
                <img class="img-fluid w-100" src="{{item.image}}" style="object-fit: cover;">
                <div class="bg-white p-4">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                            routerLink="/news">News</a>
                        <p innerHTML="{{item.fdate}}"><small>{{item.fdate}}</small></p>
                    </div>
                    <p class="m-0" innerHTML="{{item.desc}}">{{item.desc}}</p>
                </div>
            </div>
        </div>
        <hr>
    </ng-container> 
</div>

<pagination-controls (pageChange)="p = $event"></pagination-controls>