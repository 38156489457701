import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-newspaper',
  templateUrl: './newspaper.component.html',
  styleUrls: ['./newspaper.component.css']
})
export class NewspaperComponent implements OnInit {
  SpResult: any;
  constructor(private mainService: MainService, private dom: DomSanitizer) { }
  ngOnInit(): void {
    this.publicList();
  }
  publicList() {
    const MoObj: any = {};
    MoObj.Pid = "d7ada88b-3fdf-4366-a5c2-d846c58590c4";
    MoObj.PkWebSite_PDFPath = 0;
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table1;
    });
  }
  getEmbedUrl(img2) {
    return this.dom.bypassSecurityTrustResourceUrl(img2);
  }
}
