<!-- Breaking News Start -->
<div class="container-fluid mt-5 mb-3 pt-3" id="committees">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <div class="section-title border-right-0 mb-0" style="width: 180px;">
                        <h4 class="m-0 text-uppercase font-weight-bold">Tranding</h4>
                    </div>
                    <div class=" position-relative d-inline-flex align-items-center bg-white border border-left-0"
                        style="width: calc(100% - 180px); padding-right: 100px;">
                        <marquee onmouseover="this.stop();" onmouseleave="this.start();">
                            <ng-container *ngFor="let item of SpResult">
                            <a innerHTML="{{item.newsDec}}"  style="float:left; margin-right: 15px;" class="BtnFontColor text-uppercase font-weight-semi-bold MarqueeTag"  routerLink="/news">
                                {{item.newsDec}}
                            </a>
                            </ng-container>
                        </marquee>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breaking News End -->
<!-- News With Sidebar Start -->
<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <!-- News Detail Start -->
                <div class="position-relative mb-3">
                    <div class="bg-white border border-top-0 p-4">
                        <h3 class="text-uppercase font-weight-bold mb-3">about kendriya manavadhikar</h3>
                        <img class="img-fluid w-50 float-left mr-4 mb-2" src="assets/img/KenLogo.jpg" width="100px">
                        <p style="text-align: justify;">
                            The organization was established to promote the protection of Human Rights and Fundamental
                            Freedom for all, it was certified in 2016. The organization workstowards mobilising the
                            youth in the direction of societal welfare. It gives importance to the protection of the
                            environment and maintenance of the ecological balance. Education at all levels has been
                            stressed upon as a crucial factor for theupliftment of the masses. Several attempts are
                            being made to provide vocational training to develop the required skills. We struggle to
                            provide assistance to thephysically and mentally challenged in the form of contribution,
                            subscription ormonetary aid. One of our primary goals include making women self dependent
                            andeconomically independent. We also set up camps to spread awareness about the ills of
                            smoking the cigarettes, alcohol consumption and the cure for sexually transmitted diseases
                            among the general public. This is to be done by raising fundsfrom international
                            organizations such as UNICEF, WHO, NEPA etc. Weencourage the emerging artists in the field
                            of music, dance, liberal arts, sports etc. We undertake efforts to create a sense of
                            brotherhood, love humanity, affection,cooperation and friendship amongst the members of the
                            society and beneficiaries.
                        </p>
                        <h6><b>KENDRIYA MANAVADHIKAR SANGATAN TRUST</b></h6>
                        <a routerLink="/registration"><img class="img-fluid" src="assets/img/joinnow.gif" width="300px"
                                alt=""></a>
                        <hr>
                        <h3 class="text-uppercase font-weight-bold mb-3">HUMAN RIGHTS TASK FORCE</h3>
                        <img class="img-fluid w-50 float-right mr-4 mb-2" src="assets/img/Task.jpg">
                        <p style="text-align: justify;">
                            This branch of the organization was established in 2018 to serve the nation in orderto
                            promote religious, social and cultural harmony along with the feeling ofpatriotism among
                            citizens. We encourage individuals to take part in the electoralprocess and serve the nation
                            at various levels. We have joined hands with peoplefrom different backgrounds this includes
                            women, children, SCs, STs, OBCs toform an organisation which starts at the grass-root level.
                            Support is provided toindividuals excelling in the field of dance, music, drama etc. We
                            pledge to servethe nation by collaborating with the police force, military, anti- corruption
                            bureauetc. We organise individuals for protests, demonstrations etc which helps themrealise
                            their rights and award them for their contribution in the respective fields. We create aware
                            citizens and help the population avail the benefits of thegovernmental schemes. Assistance
                            is given to people who are willing to establishsmall scale business, hold training sessions,
                            promote awareness camps etc. Parents
                            who are unable to wed their young daughters receive financial assistance from us. Our aim is
                            to create a country without deprivation by helping the citizens accessthe best of the
                            available opportunities.
                        <h6><b>KENDRIYA MANAVADHIKAR SANGATAN TRUST</b></h6>
                        <a routerLink="/registration"><img class="img-fluid" src="assets/img/joinnow.gif" width="300px"
                                alt=""></a>
                        <hr>
                        <h3 class="text-uppercase font-weight-bold mb-3">about kendriya manavadhikar</h3>
                        <img class="img-fluid w-50 float-left mr-4 mb-2" src="assets/img/AdimanyataPatrakar.jpg"
                            width="100px">
                        <p style="text-align: justify;">
                            This organization was established in 2017 to bring together the people working inthe media
                            sector and forming their association to present the citizens with unbiased information. This
                            is aimed to benefit the media persons by making them aware ofthe various schemes of the
                            government and helping them avail the advantages ofthe same. All members are encouraged to
                            take part in the political process in orderto represent the oppressed. We believe in
                            striving to make the citizens aware oftheir rights and provide them with an opportunity to
                            make their voices heard. People from all walks of life contributing in various sectors are
                            welcomed by theorganization. Individuals are inspired to explore and set up training centres
                            for self-motivated groups. Women receive support to be a part of family welfare
                            initiativesand learn about maintenance of their individuality while discovering their
                            ability tobe creative at the same time. The youth is emboldened to become a part of themedia
                            by imparting the knowledge of journalism through various camps set up bythe organization.
                            People are taught about the benefits of a healthy lifestyle. Importance is given to
                            integrity, honesty, courage, loyalty, fortitude and the feeling of unity rather than the
                            divisions created by artificial boundaries among thecitizens. Individuals are motivated to
                            become independent intellectually, emotionally and financially.
                        </p>
                        <h6><b>KENDRIYA MANAVADHIKAR SANGATAN TRUST</b></h6>
                        <a routerLink="/registration"><img class="img-fluid" src="assets/img/joinnow.gif" width="300px"
                                alt=""></a>
                        <hr>
                    </div>
                    <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                        <div class="d-flex align-items-center">
                        </div>
                        <div class="d-flex align-items-center">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <!-- Social Follow Start -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">Follow Us</h4>
                    </div>
                    <div class="bg-white border border-top-0 p-3">
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #39569E;">
                            <i class="fab fa-facebook-f text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Fans</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #52AAF4;">
                            <i class="fab fa-twitter text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #0185AE;">
                            <i class="fab fa-linkedin-in text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Connects</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #C8359D;">
                            <i class="fab fa-instagram text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #DC472E;">
                            <i class="fab fa-youtube text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Subscribers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none" style="background: #055570;">
                            <i class="fab fa-vimeo-v text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                    </div>
                </div>
                <!-- Social Follow End -->
                <!-- Ads Start -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">NEWS & EVENTS </h4>
                    </div>
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/kovindd.jpg" alt=""></a>
                     <p>With President of India</p>  
                     <b><p style="margin-top: -20px;">Mr. Ram Nath Kovind</p></b> 
                    </div>
                </div>
                <div class="mb-3">
                    
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/MD1.jpg" alt=""></a>
                     <b><p>Message From Founder</p></b>
                     <p style="text-align:justify;">"I strongly believe that the youth of our country 
                        enable us to hope for a prosperous future. The aware citizens form the building 
                        block of a progressive nation. It is quintessential for every individual to be 
                        informed of their fundamental rights alongwith the knowledge of our constitution. 
                        It is therefore our promise and commitment to serve the people of the nation to the 
                        best of their interest."</p>
                        <b><p>Dr. Milind Dahiwale</p></b>
                        <p>Founder</p>
                        <p style="margin-top: -20px;">+(91) 9552011005</p>
                    </div>
                </div>
                <!-- Ads End -->
                <!-- Popular News End -->
            </div>
        </div>
    </div>
</div>
<!-- News With Sidebar End -->