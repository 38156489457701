import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-website-photo-gallery-header',
  templateUrl: './website-photo-gallery-header.component.html',
  styleUrls: ['./website-photo-gallery-header.component.css']
})
export class WebsitePhotoGalleryHeaderComponent implements OnInit {
  Galleryheadingform: FormGroup;
  SpResult: any;
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.Galleryheadingform = this.fb.group({
      GalleryId: [''],
      GalleryHeading: ['']
    });
  }
  Insert() {
    const MoObj: any = {};
    MoObj.Pid = '633e2df7-8507-4132-9871-dd967cff5cc2';
    MoObj.HeaderDec = this.Galleryheadingform.value.GalleryHeading;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = '03205413-e47a-404a-8da7-4a7a531121cc';
    MoObj.PkWebSite_PhotoGalleryHeader = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  Reset(){
    this.Galleryheadingform.reset();
  }

}
