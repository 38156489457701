<div class="container-fluid" id="hero">
    <div class="row">
        <div class="col-lg-7 px-0">
            <ngb-carousel *ngIf="SpResult">
                <ng-template ngbSlide *ngFor="let event of SpResult">
                  <img [src]="event.folderPath" alt="Random slide" width="805px">
                  <div class="carousel-caption">
                    <div class="overlay">
                        <div class="mb-10">
                            <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                routerLink="/news">news</a>
                            <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                        </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-carousel>        
              <!-- <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner position-relative">
                            <div *ngFor="let item of SpResult; let i=index" [class]="(i === 18) ? 'active' : 'inactive'">
                                <div class="position-relative overflow-hidden carousel-item " style="height: 500px;">
                                    <img class="img-fluid h-100" src="{{item.folderPath}}" style="object-fit: cover;">
                                    <div class="overlay">
                                        <div class="mb-2">
                                            <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                                routerLink="/news">news</a>
                                            <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                                        </div>
                                    </div>
                            </div>
                            </div> -->
                    <!-- <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/mainnews/3.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">news</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/mainnews/4.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">news</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/5.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/6.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/7.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="position-relative overflow-hidden carousel-item" style="height: 500px;">
                        <img class="img-fluid h-100" src="assets/img/8.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">news</a>
                                <a class="text-white MarqueeTag" href="">{{date|date}}</a>
                            </div>
                        </div>
                    </div> -->
                <!-- </div>
            </div> -->
        </div>
        <div class="col-lg-5 px-0">
            <div class="row mx-0">
                <div class="col-md-6 px-0">
                    <div class="position-relative overflow-hidden d-none d-lg-block" style="height: 250px;">
                        <img class="img-fluid w-100 h-100" src="assets/img/mainnews/1.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href=""><small>{{date|date}}</small></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 px-0">
                    <div class="position-relative overflow-hidden d-none d-lg-block" style="height: 250px;">
                        <img class="img-fluid w-100 h-100" src="assets/img/mainnews/2.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">news</a>
                                <a class="text-white MarqueeTag" href=""><small>{{date|date}}</small></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 px-0">
                    <div class="position-relative overflow-hidden d-none d-lg-block" style="height: 250px;">
                        <img class="img-fluid w-100 h-100" src="assets/img/mainnews/3.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href=""><small>{{date|date}}</small></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 px-0">
                    <div class="position-relative overflow-hidden d-none d-lg-block" style="height: 250px;">
                        <img class="img-fluid w-100 h-100" src="assets/img/mainnews/4.jpg" style="object-fit: cover;">
                        <div class="overlay">
                            <div class="mb-2">
                                <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor"
                                    routerLink="/news">News</a>
                                <a class="text-white MarqueeTag" href=""><small>{{date|date}}</small></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>