import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-website-news-type',
  templateUrl: './website-news-type.component.html',
  styleUrls: ['./website-news-type.component.css']
})
export class WebsiteNewsTypeComponent implements OnInit {
  newstypeform: FormGroup;
  SpResult: any;
  constructor(private fb: FormBuilder, private mainservice: MainService) { }
  ngOnInit(): void {
    this.newstypeform = this.fb.group({
      NewsId: [''],
      NewsDec: [''],
    });
  }
  Insert() {
    const MoObj: any = {};
    MoObj.Pid = '1f26a297-47a4-4ce8-b492-76da526e7db8';
    MoObj.TypeDec = this.newstypeform.value.NewsDec;
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
  }

  List() {
    const MoObj: any = {};
    MoObj.Pid = '116685f6-8b5c-4387-b548-976ed8770620';
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  Reset(){
    this.newstypeform.reset();
  }
}
