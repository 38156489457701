<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">Video Gallery</p>
                <form [formGroup]="videogalleryform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">Video ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="GalleryId">
                    </div>
                    <div class="form-group">
                        <label for="sel1">Video Gallery Header:</label>
                        <select class="form-control" id="sel1" formControlName="GalleryHeader">
                            <option value="" selected disabled>Please Select</option>
                            <option *ngFor="let item of SpResult" value="{{item.pkWebSite_VideoGalleryHeader}}">
                                {{item.headerDec}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Video Decription:</label>
                        <input type="text" class="form-control" name="ImageId" id="ImageId"
                            placeholder="Video Description" formControlName="GalleryHeading">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Video Path:</label>
                        <input type="text" class="form-control" placeholder="Video" formControlName="VideoPath">
                    </div>

                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Update" disabled id="login" class="btn btn-sm mb-4 category-btn" type="button"
                        value="Update">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>ID</th>
                                    <th>Gallery Header</th>
                                    <th>Video Decription</th>
                                    <th>Video Path</th>
                                    <th>Header Description</th>
                                </thead>
                                <tbody *ngFor="let video of eventImgList">
                                    <tr>
                                        <td (click)="RowSelected(video)" onclick="window.scrollTo(0,0)">
                                            {{video.pkWebSite_VideoGallery}}</td>
                                        <td>{{video.fkWebSite_VideoGalleryHeader}}</td>
                                        <td>{{video.videoDec}}</td>
                                        <td>
                                            <iframe width="315" height="315" [src]="getEmbedUrl(video.videoPath)"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </td>
                                        <td>{{video.headerDec}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>