import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientLogoComponent } from './client-logo/client-logo.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RootComponent } from './root/root.component';
import { WebsiteEventComponent } from './website-event/website-event.component';
import { WebsiteImagesUploadComponent } from './website-images-upload/website-images-upload.component';
import { WebsiteNewsTypeComponent } from './website-news-type/website-news-type.component';
import { WebsiteNewsComponent } from './website-news/website-news.component';
import { WebsitePdfComponent } from './website-pdf/website-pdf.component';
import { WebsitePhotoGalleryHeaderComponent } from './website-photo-gallery-header/website-photo-gallery-header.component';
import { WebsitePhotoGalleryComponent } from './website-photo-gallery/website-photo-gallery.component';
import { WebsiteTeamMemberComponent } from './website-team-member/website-team-member.component';
import { WebsiteTestimonialComponent } from './website-testimonial/website-testimonial.component';
import { WebsiteVideoGalleryHeaderComponent } from './website-video-gallery-header/website-video-gallery-header.component';
import { WebsiteVideoGalleryComponent } from './website-video-gallery/website-video-gallery.component';

const routes: Routes = [{
    path:'admin',
    component:DashboardComponent,
    children:[
      {path:'',component:RootComponent},
      {path:'root',component:RootComponent},
      {path:'clientLogo',component:ClientLogoComponent},
      {path:'event',component:WebsiteEventComponent},
      {path:'websiteimagesupload',component:WebsiteImagesUploadComponent},
      {path:'newstype',component:WebsiteNewsTypeComponent},
      {path:'websitenews',component:WebsiteNewsComponent},
      {path:'websitephotogallery',component:WebsitePhotoGalleryComponent},
      {path:'websitephotogalleryheader',component:WebsitePhotoGalleryHeaderComponent},
      {path:'websiteteammember',component:WebsiteTeamMemberComponent},
      {path:'websitetestimonial',component:WebsiteTestimonialComponent},
      {path:'websitevideogallery',component:WebsiteVideoGalleryComponent},
      {path:'websitevideogallyheader',component:WebsiteVideoGalleryHeaderComponent},
      {path:'websitepdf',component:WebsitePdfComponent},
    
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
