import { Component} from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.css']
})
export class CommitteesComponent{
  SpResult:any;
  constructor( private mainservice: MainService,private router:Router) {}
  ngOnInit(): void {
    this.PublicList();
   }
   PublicList(){
    var MoObj: any = {}
    MoObj.Pid = "cf5afa86-0c51-456d-9f78-31ba56b04465";
    MoObj.FkWebSite_NewsType=9;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
   }
}
