<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">Team Members</p>
                <form [formGroup]="teammemberform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">Member ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="memberId">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Member Description:</label>
                            <ck-editor name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"
                            formControlName="MemberDecription"></ck-editor>
                    </div>
                    <div class="form-group row">
                        <label for="uname"><b>Member Image</b></label>
                        <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader"
                         id="fileInput" formControlName="MemberPath"/>

                    </div>
                    <!-- <div class="form-group">
                        <label for="uname"><b>Image Url</b></label>
                        <input type="text" class="form-control" placeholder="UrlPath" >
                    </div> -->


                    <hr>
                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="Update" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Update"
                        (click)="Update()">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Member Name
                                    </th>
                                    <th>
                                        Image Path
                                    </th>

                                </thead>
                                <tbody *ngFor="let imgName of SpResult">
                                    <tr>
                                        <td (click)="RowSelected(imgName)" onClick="window.scrollTo(0,0)">
                                            {{imgName.pkWebSite_TeamMember}}
                                        </td>

                                        <td>
                                            {{imgName.memberDec}}
                                        </td>

                                        <td>
                                            <img src="{{imgName.folderPath}}">
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>