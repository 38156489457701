<div class="container-fluid pt-5 mb-3">
    <div class="container">
        <div class="section-title">
            <h4 class="m-0 text-uppercase font-weight-bold">Featured News</h4>
        </div>
        <div class="carousel-inner position-relative">
            <div class="position-relative overflow-hidden carousel-item-4" style="height: 300px;">
                <img class="img-fluid h-100" src="assets/img/news-700x435-1.jpg" style="object-fit: cover;">
                <div class="overlay">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 MarqueeTag BtnFontColor"
                            href="">Business</a>
                        <a class="text-white MarqueeTag" href=""><small>Jan 01, 2045</small></a>
                    </div>
                    <a class="h6 m-0 text-white text-uppercase font-weight-semi-bold MarqueeTag BtnFontColor"
                        href="">Lorem ipsum dolor sit amet elit...</a>
                </div>
            </div>
            <div class="position-relative overflow-hidden carousel-item-4" style="height: 300px;">
                <img class="img-fluid h-100" src="assets/img/news-700x435-2.jpg" style="object-fit: cover;">
                <div class="overlay">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 MarqueeTag BtnFontColor"
                            href="">Business</a>
                        <a class="text-white MarqueeTag" href=""><small>Jan 01, 2045</small></a>
                    </div>
                    <a class="h6 m-0 text-white text-uppercase font-weight-semi-bold MarqueeTag BtnFontColor"
                        href="">Lorem ipsum dolor sit amet elit...</a>
                </div>
            </div>
            <div class="position-relative overflow-hidden carousel-item-4" style="height: 300px;">
                <img class="img-fluid h-100" src="assets/img/news-700x435-3.jpg" style="object-fit: cover;">
                <div class="overlay">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BtnFontColor"
                            href="">Business</a>
                        <a class="text-white" href=""><small>Jan 01, 2045</small></a>
                    </div>
                    <a class="h6 m-0 text-white text-uppercase font-weight-semi-bold MarqueeTag BtnFontColor"
                        href="">Lorem ipsum dolor sit amet elit...</a>
                </div>
            </div>
            <div class="position-relative overflow-hidden carousel-item-4" style="height: 300px;">
                <img class="img-fluid h-100" src="assets/img/news-700x435-4.jpg" style="object-fit: cover;">
                <div class="overlay">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BtnFontColor"
                            href="">Business</a>
                        <a class="text-white" href=""><small>Jan 01, 2045</small></a>
                    </div>
                    <a class="h6 m-0 text-white text-uppercase font-weight-semi-bold MarqueeTag BtnFontColor"
                        href="">Lorem ipsum dolor sit amet elit...</a>
                </div>
            </div>
            <div class="position-relative overflow-hidden carousel-item-4" style="height: 300px;">
                <img class="img-fluid h-100" src="assets/img/news-700x435-5.jpg" style="object-fit: cover;">
                <div class="overlay">
                    <div class="mb-2">
                        <a class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BtnFontColor"
                            href="">Business</a>
                        <a class="text-white" href=""><small>Jan 01, 2045</small></a>
                    </div>
                    <a class="h6 m-0 text-white text-uppercase font-weight-semi-bold MarqueeTag BtnFontColor"
                        href="">Lorem ipsum dolor sit amet elit...</a>
                </div>
            </div>
        </div>
    </div>
</div>