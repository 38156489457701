import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
import * as $ from 'jquery';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-website-news',
  templateUrl: './website-news.component.html',
  styleUrls: ['./website-news.component.css']
})
export class WebsiteNewsComponent implements OnInit {
  public editorValue: string = '';
  employeeForm: FormGroup;
  SpResult: any;
  result: any;
  news: any;
  updatespresult: any;
  str: string;
  ImageResult1: string;
  data: any;
  insertnewsID: any;
  getnewsId: any;
  Token: string = localStorage.getItem('auth_token');
  updatesspresult:any;
  currentdate:any=new Date();

  public uploader: FileUploader = new FileUploader
    ({
      url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter:
        { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' }
    });
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      NewsId: [''],
      Newstype: [''],
      NewsDec: [''],
      Fromdate: ['currentdate'],
      Todate: ['currentdate'],
      ImagePath: ['']
    });
    this.select();
    this.imageUpload();
  }
 
  async Insert() {
    const MoObj: any = {};
    MoObj.Pid = '731702a1-981f-4143-bfd6-1d234c40c87a';
    MoObj.FkWebSite_NewsType = this.employeeForm.value.Newstype;
    MoObj.NewsDec = this.employeeForm.value.NewsDec;
    MoObj.FromDate = this.employeeForm.value.Fromdate;
    MoObj.ToDate = this.employeeForm.value.Todate;
    this.mainService.OneMethod(MoObj).then(async value => {
      this.result = value;
      this.insertnewsID = this.result.table[0].result;
      localStorage.setItem('newsid', this.insertnewsID);
      this.getnewsId = localStorage.getItem('newsid');
      if (this.getnewsId > 0) {
        this.data = this.uploader.uploadAll();
        let handlePromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.data);
          }, 5000)
        })
        await handlePromise
        this.imginsert();
      }
      alert(JSON.stringify(this.result.table[0].spResult));
    });
    this.Reset();
  }
  imageUpload() {
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.employeeForm.value.FileName = this.ImageResult1;
    };
  }
  imginsert() {
    const MoObj: any = {};
    MoObj.Pid = "03869b7f-2de9-4caf-a1f8-b32624fe50aa";
    MoObj.FkWebSite_News = localStorage.getItem('newsid');
    MoObj.NewsPhotoPath = this.employeeForm.value.FileName;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert("image inserted successfully");
    });
  }
  Delete() {
    const MoObj: any = {};
    MoObj.Pid = '0941dabe-4280-405b-a1e5-6f31674935dc';
    MoObj.PkWebSite_News = this.employeeForm.value.NewsId;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult;
    });
    alert(JSON.stringify(this.SpResult.table[0].spResult));
  }
  Update() {
    const MoObj: any = {};
    MoObj.Pid = 'd4c4597b-2e2c-4433-b43e-8083945624bd';
    MoObj.PkWebSite_News = this.employeeForm.value.NewsId;
    MoObj.FkWebSite_NewsType = this.employeeForm.value.Newstype;
    MoObj.NewsDec = this.employeeForm.value.NewsDec;
    MoObj.FromDate = this.employeeForm.value.Fromdate;
    MoObj.ToDate = this.employeeForm.value.Todate;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.updatesspresult = this.SpResult.table;
    });
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = '948e323c-d022-4ce0-9159-b94fef08662b';
    MoObj.PkWebSite_News = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.news = value;
      this.news = this.news.table;
    });
  }
  imageDelete(cmp) {
    const MoObj: any = {};
    MoObj.Pid = '874e19e8-aebf-4203-b7fd-c14ebca46f9a';
    MoObj.PkWebSite_NewsImg = cmp;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  Reset(){
    this.employeeForm.reset();
  }
  select() {
    const MoObj: any = {};
    MoObj.Pid = '116685f6-8b5c-4387-b548-976ed8770620';
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.updatespresult = this.SpResult.table;
    });
  }
RowSelected(u: any) {
    this.employeeForm.controls['NewsId'].setValue(u.pkWebSite_News);
    this.employeeForm.controls['Newstype'].setValue(u.fkWebSite_NewsType);
    this.employeeForm.controls['NewsDec'].setValue(u.newsDec);
    this.employeeForm.controls['Fromdate'].patchValue(this.formatDate(u.fromDate));
    this.employeeForm.controls['Todate'].patchValue(this.formatDate(u.toDate));
    window.scroll({
      top: 0
    });
    const MoObj: any = {};
    MoObj.Pid = '948e323c-d022-4ce0-9159-b94fef08662b';
    MoObj.PkWebSite_News = u.pkWebSite_News;
    this.mainService.OneMethod(MoObj).then(value => {
      const SpResult: any = value;
      this.SpResult = SpResult.table1;
    });
  }
  //this function for set date 
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}