import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { HttpModule } from '@angular/http';
import { AdminRoutingModule } from './admin-routing.module';
import { ClientLogoComponent } from './client-logo/client-logo.component';
import { RootComponent } from './root/root.component';
import { AuthGuard } from '../services/auth-guard.service';
import { AuthrizationService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { ApiConfigService } from '../services/api-config.service';
import { FileUploadModule } from 'ng2-file-upload';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import { WebsiteEventComponent } from './website-event/website-event.component';
import { WebsiteImagesUploadComponent } from './website-images-upload/website-images-upload.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { WebsiteNewsTypeComponent } from './website-news-type/website-news-type.component';
import { WebsiteNewsComponent } from './website-news/website-news.component';
import { WebsitePhotoGalleryHeaderComponent } from './website-photo-gallery-header/website-photo-gallery-header.component';
import { WebsitePhotoGalleryComponent } from './website-photo-gallery/website-photo-gallery.component';
import { WebsiteTeamMemberComponent } from './website-team-member/website-team-member.component';
import { WebsiteTestimonialComponent } from './website-testimonial/website-testimonial.component';
import { WebsiteVideoGalleryComponent } from './website-video-gallery/website-video-gallery.component';
import { WebsiteVideoGalleryHeaderComponent } from './website-video-gallery-header/website-video-gallery-header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WebsitePdfComponent } from './website-pdf/website-pdf.component';
@NgModule({
  declarations: [ ClientLogoComponent, RootComponent, WebsiteEventComponent,
                 WebsiteImagesUploadComponent, WebsiteNewsTypeComponent, WebsiteNewsComponent,
                 WebsitePhotoGalleryHeaderComponent, WebsitePhotoGalleryComponent, WebsiteTeamMemberComponent,
                  WebsiteTestimonialComponent, WebsiteVideoGalleryComponent, DashboardComponent , WebsiteVideoGalleryHeaderComponent, WebsitePdfComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    AppRoutingModule,
    HttpModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule
  ],
  providers: [AuthGuard,AuthrizationService,MainService,ApiConfigService],
})
export class AdminModule { }
