import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-website-event',
  templateUrl: './website-event.component.html',
  styleUrls: ['./website-event.component.css']
})
export class WebsiteEventComponent implements OnInit {
  employeeForm: FormGroup; SpResult: any; photogallery: any; ImageResult: any;
  str: string; ImageResult1: string;
  id: any;
  result: any;
  eventImgList: any;
  imageSpResult: any;
  imgcontrol: boolean = true;
  insertID: any;
  getId: any;
  respo: any;
  imgpath: any;
  data: any;
  Token: string = localStorage.getItem('auth_token');
  public uploader: FileUploader = new FileUploader
    ({
      url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter:
        { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' }
    });
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  public editorValue: string = '';
  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      EventId: [''],
      EventDec: [''],
      EventPic: [''],
    });
    this.imageUpload();
  }
  async Insert() {
    const MoObj: any = {};
    MoObj.Pid = 'e8f12602-a20f-4f32-b72f-3a0cec6363d8';
    MoObj.EventDec = this.employeeForm.value.EventDec;
    this.mainService.OneMethod(MoObj).then(async value => {
      this.SpResult = value;
      this.insertID = this.SpResult.table[0].result;
      localStorage.setItem('newid', this.insertID);
      this.getId = localStorage.getItem('newid');
      if (this.getId > 0) {
        this.data = this.uploader.uploadAll();
        let handlePromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.data);
          }, 5000)
        })
        await handlePromise
        this.imginsert();
      }
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  imageUpload() {
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.employeeForm.value.FileName = this.ImageResult1;
    };
  }
  imginsert() {
    var MoObj: any = {}
    MoObj.Pid = '33ece54e-1205-43fb-8b43-f1ef4229c355';
    MoObj.FkWebSite_Event = localStorage.getItem('newid');
    MoObj.EventPhotoPath = this.employeeForm.value.FileName;
    this.mainService.OneMethod(MoObj).then(value => {
      this.imageSpResult = value;
      this.imageSpResult = this.imageSpResult;
    });
  }
  Delete() {
    const MoObj: any = {};
    MoObj.Pid = 'afe31642-7b06-46e1-9bb9-987d1f7ee190';
    MoObj.PkWebSite_Event = this.employeeForm.value.EventId;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = 'fa40eab0-2f18-471c-9161-0768faaf57be';
    MoObj.PkWebSite_Event = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  Update() {
    var MoObj: any = {}
    MoObj.Pid = 'bf8a0d40-f1cc-49f2-9c89-ecf463e348d9';
    MoObj.PkWebSite_Event = this.employeeForm.value.EventId;
    MoObj.EventDec = this.employeeForm.value.EventDec;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  imageDelete(cmp) {
    var MoObj1: any = {};
    MoObj1.Pid = 'e43c3978-6283-4ca6-99dd-d652a347ba6f';
    MoObj1.PkWebSite_EventImg = cmp;
    this.mainService.OneMethod(MoObj1).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
    const value = sessionStorage.getItem('id')
    const MoObj: any = {};
    MoObj.Pid = 'fa40eab0-2f18-471c-9161-0768faaf57be';
    MoObj.PkWebSite_EventImg = value;
    this.mainService.OneMethod(MoObj).then(value => {
      this.eventImgList = value;
      this.eventImgList = this.eventImgList.table1
    });
  }
  RowSelected(u: any) {
    this.employeeForm.controls['EventId'].setValue(u.pkWebSite_Event);
    this.employeeForm.controls['EventDec'].setValue(u.eventDec);
    window.scroll({
      top: 0
    });

    const MoObj: any = {};
    MoObj.Pid = 'fa40eab0-2f18-471c-9161-0768faaf57be';
    MoObj.PkWebSite_Event = u.pkWebSite_Event;
    this.mainService.OneMethod(MoObj).then(value => {
      this.eventImgList = value;
      this.eventImgList = this.eventImgList.table1;

    });
    localStorage.setItem('id', u.pkWebSite_Event);
    // localStorage.setItem('imgpath', this.employeeForm.value.FileName);

  }
  Reset() {
    this.employeeForm.reset();
  }
}
