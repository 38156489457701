<!-- Breaking News Start -->
<div class="container-fluid mt-5 mb-3 pt-3" id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <div class="section-title border-right-0 mb-0" style="width: 180px;">
                        <h4 class="m-0 text-uppercase font-weight-bold">Tranding</h4>
                    </div>
                    <div class=" position-relative d-inline-flex align-items-center bg-white border border-left-0"
                        style="width: calc(100% - 180px); padding-right: 100px;">
                        <marquee onmouseover="this.stop();" onmouseleave="this.start();">
                            <ng-container *ngFor="let item of SpResult">
                            <a innerHTML="{{item.newsDec}}" style="float:left; margin-right: 15px;" class="BtnFontColor text-uppercase font-weight-semi-bold MarqueeTag"  routerLink="/news">
                                {{item.newsDec}}
                            </a>
                            </ng-container>
                        </marquee>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breaking News End -->
<!-- News With Sidebar Start -->
<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <!-- News Detail Start -->
                <div class="position-relative mb-3">
                    <div class="bg-white border border-top-0 p-4">
                        <h3 class="text-uppercase font-weight-bold mb-3">about kendriya manavadhikar</h3>
                        <img class="img-fluid w-50 float-left mr-4 mb-2" src="assets/img/kovindd.jpg">
                        <p style="text-align: justify;">
                            नमस्कार,<br>
                            मानवाधिकार क्षेत्र में आप सभीका हार्दिक स्वागत है | वर्ष 2012 से केन्द्रीय मानवाधिकार
                            समाचारपत्र निरंतर प्रकाशितहोकर आप तक पोहच रहा है |
                            हमारा यह अखबार साप्ताहिक एवं सांध्य दैनिक है | केन्द्रीय मानवाधिकार समाचारपत्र राष्ट्रीय
                            स्तर पर सोसीअल मीडिया मे ज्यादा से ज्यादा पसंद किया जा रहा है | समाज मे फैली व्याप्त बुराई
                            को दूर करने हेतु, नशामुक्ति, बालश्रम, महिला उत्पीडन , श्रमिक शोषण, भ्रस्टाचार, शैक्षणिक,
                            विसंगतिया, दहेज़ प्रता, साम्प्रदायिकता, न्यायिक प्रणाली, मानवीय दुर्व्यवार, जैसी और अन्य
                            सामाजिक बुराईको इस समाचार पत्र के माध्यम से समाज मे जन जाग्रति कर इसे दूर करने का हम प्रयास
                            कर रहे हे | ऐसे कोई भी घटित मामले का सप्रमाण विवरण हमें भेजे केन्द्रीय मानवधिकार समाचार पत्र
                            आप के द्वारा भेजे गए सभी मामलोंको निशुल्क प्रकाशित कर संबंधितो के असली चेहरे दुनिया के सामने
                            लाएगा | संबंधितो के असली चेहरे दुनिया के सामने लाएगा |
                            इस प्रयास में आप हमारे सहयोगी बने।
                            हमारा समाचार पत्र निशुल्क प्राप्त हेतु आप अपना पता पिन कोड के साथ ,मोबाइल नंबर ,ईमेल आयडी
                            हमें भेजे. जो भी व्यक्ति निशुल्क समाज सेवा करना चाहता है, हमारे केन्द्रीय मानवाधिकार परिवार
                            से जुडना चाहता हे वह व्यक्ति आमंत्रित हे |

                        </p>
                        <hr>
                        
                        <p style="text-align: justify;">
                            Greetings!
                            <br>
                            Our organization welcomes you. Established in 2012, Kendriya Manavadhikar has made several
                            attempts to serve the best of your interest and has presented you with unbiased news without
                            any fear or favour.
                            Our newspaper comes in a daily as well as weekly edition. The newspaper has gained
                            widespread acceptance in the social media. We have consistently worked towards the
                            upliftment of society by helping root out the evils such as drug addiction, harassment of
                            women, child labor, labor exploitation, corruption, dowry deaths, educational anomaly,
                            feeling of communalism, unresponsive judicial system, misconduct by citizens etc.
                            with the help of this newspaper and spread awareness among the fellow citizens by working
                            alongside the government administrative machinery and related organizations to gather the
                            required evidence in order to unmask the guilty and throw light on their true character.
                            We request you to become a part of the initiative and work towards the greater cause.
                        </p>
                        Regards,<br>
                        Founder And Chief Editor<br>
                        Dr.Milind Dahivale<br>
                        Kendriya Manavadhikar<br>
                        09371247344, 09552011005
                    </div>
                    <div class="d-flex justify-content-between bg-white border border-top-0 p-4">
                        <div class="d-flex align-items-cente">
                        </div>
                        <div class="d-flex align-items-center">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <!-- Social Follow Start -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">Follow Us</h4>
                    </div>
                    <div class="bg-white border border-top-0 p-3">
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #39569E;">
                            <i class="fab fa-facebook-f text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Fans</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #52AAF4;">
                            <i class="fab fa-twitter text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #0185AE;">
                            <i class="fab fa-linkedin-in text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Connects</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #C8359D;">
                            <i class="fab fa-instagram text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #DC472E;">
                            <i class="fab fa-youtube text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Subscribers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none" style="background: #055570;">
                            <i class="fab fa-vimeo-v text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                    </div>
                </div>
                <!-- Social Follow End -->
                <!-- Ads Start -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">NEWS & EVENTS </h4>
                    </div>
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/kovindd.jpg" alt=""></a>
                     <p>With President of India</p>  
                     <b><p style="margin-top: -20px;">Mr. Ram Nath Kovind</p></b> 
                    </div>
                </div>
                <div class="mb-3">
                    
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/MD1.jpg" alt=""></a>
                     <b><p>Message From Founder</p></b>
                     <p style="text-align:justify;">"I strongly believe that the youth of our country 
                        enable us to hope for a prosperous future. The aware citizens form the building 
                        block of a progressive nation. It is quintessential for every individual to be 
                        informed of their fundamental rights alongwith the knowledge of our constitution. 
                        It is therefore our promise and commitment to serve the people of the nation to the 
                        best of their interest."</p>
                        <b><p>Dr. Milind Dahiwale</p></b>
                        <p>Founder</p>
                        <p style="margin-top: -20px;">+(91) 9552011005</p>
                    </div>
                </div>

                <!-- Ads End -->
            </div>
        </div>
    </div>
</div>
<!-- News With Sidebar End -->