<div class="container" id="video">
  <div class="row">
    <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h1 class="gallery-title pt-5">Video gallery</h1>
    </div>
    <div>
      <ng-container *ngFor="let drop of table">
        <a><button class="btn btn-default filter-button mr-2"
            (click)=openlist(drop.pkWebSite_VideoGalleryHeader)>{{drop.headerDec}}</button></a>
      </ng-container>
    </div>
    <br />
    <div id="containerselected">
      <div id="containerAll">
        <ng-container *ngFor="let listitem of table2">
          <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
            <a href="{{listitem.videoPath}}" (click)=openlistt(listitem.videoPath) data-toggle="modal"
            data-target="#exampleModal">
            <iframe width="315" height="315" [src]="getEmbedUrl(listitem.videoPath)" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </a>
          </div>
        </ng-container>
      </div>
      <ng-container *ngFor="let listitem of list">
        <ng-container *ngIf="selectedList === listitem.fkWebSite_VideoGalleryHeader">
          <div class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
            <a href="{{listitem.videoPath}}" (click)=openlistt(listitem.videoPath) data-toggle="modal"
              data-target="#exampleModal">
              <iframe width="315" height="315" [src]="getEmbedUrl(listitem.videoPath)" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </a>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <ng-container *ngFor="let listitem of list">
      <ng-container *ngIf="selectedListvideo===listitem.videoPath">
        <div class="gallery_product col-lg-12 col-md-12 col-sm-12 col-xs-12 filter sprinkle">
          <iframe width="315" height="315" [src]="getEmbedUrl(listitem.videoPath)" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>