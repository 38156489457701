import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-website-video-gallery',
  templateUrl: './website-video-gallery.component.html',
  styleUrls: ['./website-video-gallery.component.css']
})
export class WebsiteVideoGalleryComponent implements OnInit {

  videogalleryform: FormGroup;
  SpResult: any;
  eventImgList: any;
  constructor(private fb: FormBuilder, private mainService: MainService, private Domsanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.videogalleryform = this.fb.group({
      GalleryId: [''],
      GalleryHeader: [''],
      GalleryHeading: [''],
      VideoPath: [''],
    });
    this.WebSite_VideoGalleryHeader_SelectDDL();

  }
  Insert() {
    const MoObj: any = {};
    MoObj.Pid = '2b0bc822-249e-4d58-84f1-a325e5125406';
    MoObj.FkWebSite_VideoGalleryHeader = this.videogalleryform.value.GalleryHeader;
    MoObj.VideoDec = this.videogalleryform.value.GalleryHeading;
    MoObj.VideoPath = this.videogalleryform.value.VideoPath;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = 'ae21ebe1-8253-4f75-a206-84b31644c91a';
    MoObj.PkWebSite_VideoGallery = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.eventImgList = value;
      this.eventImgList = this.eventImgList.table;
    console.log(this.eventImgList);
    });
  }

  Delete() {
    const MoObj: any = {};
    MoObj.Pid = '91c9f635-e2a9-4775-ae71-d7c820e5b2ac';
    MoObj.PkWebSite_VideoGallery = this.videogalleryform.value.GalleryId;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }
  RowSelected(u: any) {
    this.videogalleryform.controls['GalleryId'].setValue(u.pkWebSite_VideoGallery);
    this.videogalleryform.controls['GalleryHeader'].setValue(u.fkWebSite_VideoGalleryHeader);
    this.videogalleryform.controls['GalleryHeading'].setValue(u.videoDec);
    this.videogalleryform.controls['VideoPath'].setValue(u.videoPath);
  }
  WebSite_VideoGalleryHeader_SelectDDL() {
    const MoObj: any = {};
    MoObj.Pid = 'c2fa21f4-6c09-4319-bc0b-e3f717b539f0';
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }
  getEmbedUrl(img2) {
    return this.Domsanitizer.bypassSecurityTrustResourceUrl(img2);
  }
  Reset(){
    this.videogalleryform.reset();
  }
}