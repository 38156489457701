import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';

@Component({
  selector: 'app-website-photo-gallery',
  templateUrl: './website-photo-gallery.component.html',
  styleUrls: ['./website-photo-gallery.component.css']
})
export class WebsitePhotoGalleryComponent implements OnInit {

  Photogalleryform: FormGroup; SpResult: any; photogallery: any; ImageResult: any;
  str: string; ImageResult1: string;
  Token: string = localStorage.getItem('auth_token');
  DropdownLIst: any;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' } });
  data:any;
  dataa:any;
  deleteproperty:any;
  constructor(private fb: FormBuilder, private mainService: MainService) { }

  ngOnInit(): void {
    this.Photogalleryform = this.fb.group({
      GalleryID: [''],
      GalleryHeader: [''],
      ImageName: [''],
      ImagePath: ['']
    });

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.Photogalleryform.value.FileName = this.ImageResult1;
   
 
    };
  
  this.DropdownList();
  }
  async Insert() {
    const MoObj: any = {};
    MoObj.Pid = '14c02ba0-e564-482d-9013-be4a153c6a6e';
    MoObj.FkWebSite_PhotoGalleryHeder = this.Photogalleryform.value.GalleryHeader;
    MoObj.PhotoDec = this.Photogalleryform.value.ImageName;
    localStorage.setItem('imagename',MoObj.PhotoDec);
    this.data=localStorage.getItem('teamdescription');
    if(this.data!==""){
      this.dataa = this.uploader.uploadAll();
      let handlePromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.dataa);
        }, 5000)
      })
      await handlePromise
      MoObj.PhotoPath = this.Photogalleryform.value.FileName;
     }
    this.mainService.OneMethod(MoObj).then(value => {
  this.SpResult = value;
  alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  Delete() {
    const MoObj: any = {};
    MoObj.Pid = '25c8f30a-7f06-41ed-a47b-7e55bf0c8ef1';
    MoObj.PkWebSite_PhotoGallery = this.Photogalleryform.value.GalleryID;
    this.mainService.OneMethod(MoObj).then(value => {
      this.deleteproperty = value;
      alert(JSON.stringify(this.deleteproperty.table[0].spResult));
    });
    this.List();
  }

  List() {

    const MoObj: any = {};
    MoObj.Pid = '3e5c60a3-978a-4898-94ec-01746e1a8372';
    MoObj.PkWebSite_PhotoGallery = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.photogallery = value;
      this.photogallery = this.photogallery.table1;
  
    });

  }
  DropdownList() {
    const MoObj: any = {};
    MoObj.Pid = 'a5a3363c-c68a-403e-a4a2-8085aea5fcfc';
    this.mainService.OneMethod(MoObj).then(value => {
      this.DropdownLIst = value;
      this.DropdownLIst = this.DropdownLIst.table;
    });
  }
  RowSelected(u: any) {
    this.Photogalleryform.controls['GalleryID'].setValue(u.pkWebSite_PhotoGallery);
    this.Photogalleryform.controls['GalleryHeader'].setValue(u.fkWebSite_PhotoGalleryHeder);
    this.Photogalleryform.controls['GalleryHeading'].setValue(u.photoDec);
    this.Photogalleryform.controls['ImagePath'].setValue(u.photoPath);
    window.scroll({
      top: 0
    });
  }
  Reset(){
    this.Photogalleryform.reset();
  }
}
