<div class="container-fluid bg-dark py-3 mb-3">
    <div class="container">
        <div class="row align-items-center bg-dark">
            <div class="col-12">
                <marquee>
                    <ng-container *ngFor="let item of SpResult"><img class="mb-5" src="{{item.folderPath}}" alt="...">
                    </ng-container>
                </marquee>
            </div>
        </div>
    </div>
</div>