import { Component } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-herosection',
  templateUrl: './herosection.component.html',
  styleUrls: ['./herosection.component.css']
})
export class HerosectionComponent {
  date: any = new Date();
  SpResult:any;
  constructor( private mainservice: MainService,private router:Router) {}
  ngOnInit(): void {
    this.PublicList();
   }
   PublicList(){
    var MoObj: any = {}
    MoObj.Pid = "a2f71d3f-9b36-46cc-963d-86e09b4f63ec";
    MoObj.FkWebSite_PhotoGalleryHeder=15;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table1;
    });
   }
}
