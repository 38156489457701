<!-- Contact Start -->
<div class="container-fluid mt-5 pt-3" id="registration">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="section-title mb-0">
                    <h4 class="m-0 text-uppercase font-weight-bold">Apply For Registration</h4>
                </div>
                <div class="bg-white border border-top-0 p-4 mb-3">
                    <form [formGroup]="form">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">For Post</label>
                                    <select class="form-control BorderRadius" id="exampleFormControlSelect1"
                                        formControlName="post" [ngClass]="{ 'is-invalid': submitted && f.post.errors }">
                                        <option>-----Select Post----</option>
                                        <option>Reporter</option>
                                        <option>News Anchor</option>
                                        <option>News Editor</option>
                                        <option>News Cameraman</option>
                                    </select>
                                    <div *ngIf="submitted && f.post.errors" class="invalid-feedback">
                                        <div *ngIf="f.post.errors.required">Post is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group ">
                                    <label for="exampleFormControlInput1">Name</label>
                                    <input type="text" class="form-control BorderRadius" formControlName="Name" 
                                    [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
                                        id="exampleFormControlInput1" placeholder="Name" >
                                        <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                            <div *ngIf="f.Name.errors.required"> Name is required</div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="form-group ">
                                    <label for="exampleFormControlInput1">Email</label>
                                    <input type="email" class="form-control BorderRadius" formControlName="Email"
                                     [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
                                        id="exampleFormControlInput1" placeholder="name@example.com" >
                                        <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                                            <div *ngIf="f.Email.errors.required"> Email is required</div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="form-group  ">
                                    <label for="exampleFormControlInput1">Mob. No.</label>
                                    <input type="email" class="form-control BorderRadius" formControlName="Phone"
                                    [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }"   
                                    id="exampleFormControlInput1" placeholder="Mobile No.">
                                        <div *ngIf="submitted && f.Phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.Phone.errors.required"> Mobile No. is required</div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="form-group ">
                                    <label for="exampleFormControlTextarea1">Address</label>
                                    <textarea class="form-control BorderRadius" id="exampleFormControlTextarea1"
                                        rows="3" formControlName="Address" [ngClass]="{ 'is-invalid': submitted && f.Address.errors }">
                                    </textarea>
                                        <div *ngIf="submitted && f.Address.errors" class="invalid-feedback">
                                            <div *ngIf="f.Address.errors.required"> Address is required</div>
                                        </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-primary font-weight-semi-bold px-4 bg-primary BorderRadius"
                                    style="height: 50px;" type="submit" style="border-color: #FFCC00 ;color:#000;"
                                    (click)="
                                    Email_send()">Send
                                    Message</button>
                            </div>
                        </div>
                    </form>
                </div>
              
            </div>
            <div class="col-lg-4">
                <!-- Social Follow Start -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">Follow Us</h4>
                    </div>
                    <div class="bg-white border border-top-0 p-3">
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #39569E;">
                            <i class="fab fa-facebook-f text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Fans</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #52AAF4;">
                            <i class="fab fa-twitter text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #0185AE;">
                            <i class="fab fa-linkedin-in text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Connects</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #C8359D;">
                            <i class="fab fa-instagram text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none mb-3"
                            style="background: #DC472E;">
                            <i class="fab fa-youtube text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Subscribers</span>
                        </a>
                        <a href="" class="d-block w-100 text-white text-decoration-none" style="background: #055570;">
                            <i class="fab fa-vimeo-v text-center py-4 mr-3"
                                style="width: 65px; background: rgba(0, 0, 0, .2);"></i>
                            <span class="font-weight-medium">12,345 Followers</span>
                        </a>
                    </div>
                </div>
                <!-- Social Follow End -->
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">NEWS & EVENTS </h4>
                    </div>
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/kovindd.jpg" alt=""></a>
                     <p>With President of India</p>  
                     <b><p style="margin-top: -20px;">Mr. Ram Nath Kovind</p></b> 
                    </div>
                </div>
                <div class="mb-3">
                    
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/MD1.jpg" alt=""></a>
                     <b><p>Message From Founder</p></b>
                     <p style="text-align:justify;">"I strongly believe that the youth of our country 
                        enable us to hope for a prosperous future. The aware citizens form the building 
                        block of a progressive nation. It is quintessential for every individual to be 
                        informed of their fundamental rights alongwith the knowledge of our constitution. 
                        It is therefore our promise and commitment to serve the people of the nation to the 
                        best of their interest."</p>
                        <b><p>Dr. Milind Dahiwale</p></b>
                        <p>Founder</p>
                        <p style="margin-top: -20px;">+(91) 9552011005</p>
                    </div>
                </div>

              
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->