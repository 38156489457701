<div class="container page-top" id="achievement">
    <div class="row">
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/CIRT3.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/CIRT3.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/CIRT2.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/CIRT2.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/CIRT1.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/CIRT1.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/e.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/e.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/w.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/w.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
            <a href="assets/img/q.jpg" class="fancybox" rel="ligthbox">
                <img src="assets/img/q.jpg" class="zoom img-fluid " alt="">
            </a>
        </div>
    </div>
</div>