import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-website-testimonial',
  templateUrl: './website-testimonial.component.html',
  styleUrls: ['./website-testimonial.component.css']
})
export class WebsiteTestimonialComponent implements OnInit {
  testimonialform: FormGroup;
  SpResult: any;
  Token: string = localStorage.getItem('auth_token');
  str: string;
  ImageResult1: string;
  @ViewChild('fileInput') fileInput;
  public uploader: FileUploader = new FileUploader
  ({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: 
  { PkSystemUploadDocSetting: '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' } });
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.testimonialform = this.fb.group({
      TesttimonialId: [''],
      ImagePath: [''],
      Clientname: [''],
      Designation: [''],
      Decription: [''],
      imageUpload: [''],
      FileName: ['']
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.str = response;
      this.ImageResult1 = this.str.replace('\'', '');
      this.str = response;
      this.ImageResult1 = this.str.replace('\'', '');
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", '');
      this.ImageResult1 = this.ImageResult1.replace(",'}", '');
      this.testimonialform.value.FileName = this.ImageResult1;
      this.testimonialform.controls['ImagePath'].setValue(this.ImageResult1);
    };

  }
  Insert() {
    const MoObj: any = {};
    MoObj.Pid = 'a256e19f-7cfa-466c-8d2f-ac396a0be51c';
    MoObj.ImgPath = this.testimonialform.value.ImagePath;
    MoObj.ClientName = this.testimonialform.value.Clientname;
    MoObj.Designation = this.testimonialform.value.Designation;
    MoObj.TestimonialDec = this.testimonialform.value.Decription;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }

  Delete() {
    const MoObj: any = {};
    MoObj.Pid = '2c93742d-0eb3-4880-84c8-f0350e2d8d1d';
    MoObj.Pk_WebSite_Testimonial = this.testimonialform.value.TesttimonialId;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }
  List() {
    const MoObj: any = {};
    MoObj.Pid = 'd85197fa-7c1c-452f-ba6a-906f71f357bd';
    MoObj.Pk_WebSite_Testimonial = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
    });
  }

  Update() {
    const MoObj: any = {};
    MoObj.Pid = 'ab394466-7d02-44eb-ba25-4c7b68c8756d';
    MoObj.Pk_WebSite_Testimonial = this.testimonialform.value.TesttimonialId;
    MoObj.ImgPath = this.testimonialform.value.ImagePath;
    MoObj.ClientName = this.testimonialform.value.Clientname;
    MoObj.Designation = this.testimonialform.value.Designation;
    MoObj.TestimonialDec = this.testimonialform.value.Decription;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;

    });
    this.List();
  }




  upload(event: any) {


    const fileList: FileList = event.target.files;


    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (event: any) => {

      const file: File = fileList[0];


      const temp = file.name;
      this.testimonialform.value.ImagePath = temp;


      this.mainService.ImageUploader(file).subscribe(value => {
      });

    };
  }

  RowSelected(u: any) {
    this.testimonialform.controls['TesttimonialId'].setValue(u.pk_WebSite_Testimonial);
    this.testimonialform.controls['Clientname'].setValue(u.clientName);
    this.testimonialform.controls['Designation'].setValue(u.designation);
    this.testimonialform.controls['Decription'].setValue(u.testimonialDec);
  }
  Reset(){
    this.testimonialform.reset();
  }

}
