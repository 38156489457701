import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  ApiResult: any;
   emailId: any="manavadhikar2012@gmail.com";
  form: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder, private mainService: MainService, private router: Router) { }
  ngOnInit(): void {
    this.form = this.fb.group({
      Email: ['',[Validators.required, Validators.email]],
      Name: ['',Validators.required],
      Phone: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      post: ['',Validators.required],
      Address: ['',Validators.required],
    });
   
  }
  get f() { return this.form.controls; }
  Email_send() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    var name = this.form.get("Name").value;
    var address = this.form.get("Address").value;
    var mobileNo = this.form.get("Phone").value;
    var email = this.form.get("Email").value;
    var post = this.form.get("post").value;
    var EmailBodyMsg = "<table ><thead><tr><th>Kendriya Manavadhikar</th></tr></thead><tbody><tr><td>Name:</td><td>" + name + "</td></tr><tr><td>Phone-No:</td><td>" + mobileNo + "</td></tr><tr><td>Message:</td><td>" + address + "</td></tr><tr><td>Email:</td><td>" + email + "</td></tr></tr><tr><td>Post:</td><td>" + post + "</td></tr></tbody></table>";
    var Moobj: any = {};
    Moobj.EmailSubject = "For Contact Us";
    Moobj.EmailTo =this.emailId;
    Moobj.PkSystemEmailSetting = 4;
    Moobj.message = EmailBodyMsg;
    this.mainService.emailMethod(Moobj).subscribe(value => {
      this.ApiResult = value;

    });
    alert("Send Request Successfully");
    this.Reset();
  }
  Reset() {
    this.submitted = false;
    this.form.reset()
  }
}


