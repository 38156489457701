import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  SpResult: any;
  eventList: any = [];
  table1: any;
  table2: any;
  constructor(private fb: FormBuilder, private mainService: MainService) { }
  ngOnInit(): void {
    this.publicList();
  }
  publicList() {
    const MoObj: any = {};
    MoObj.Pid = "968151d5-eb8f-4a1d-8578-c19c1965bc1f";
    MoObj.PkWebSite_Event = 0;
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.table1 = this.SpResult.table
      this.table2 = this.SpResult.table1
      for (let i = 0; i < this.table1.length; i++) {
        let eventpk = this.table1[i].pkWebSite_Event;
        let eventdesc = this.table1[i].eventDec;
        this.table1[i]['desc'] = eventdesc;
        this.eventList.push(this.table1[i]);
        for (let j = 0; j < this.table2.length; j++) {
          let imgfk = this.table2[j].fkWebSite_Event;
          let img = this.table2[j].folderPath;
          if (eventpk == imgfk) {
            this.table2[j]['image'] = img;
            this.eventList.push(this.table2[j]);
          }

        }
      }
    });
  }
}
