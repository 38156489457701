<div class="container-fluid mt-5 pt-3" id="newspaper">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <h4 class="m-0 text-uppercase font-weight-bold">Newspapers</h4>
                        </div>
                    </div>
                    <ng-container *ngFor="let item of SpResult">
                        <div class="col-lg-12">
                            <h3>{{item.description}}</h3>
                            <a
                                class="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2 BorderRadius MarqueeTag BtnFontColor">{{item.category}}</a>
                            <p innerHTML="{{item.insertedDate|date}}"><small>{{item.insertedDate| date}}</small></p>
                            <iframe [src]="getEmbedUrl(item.folderPath)" width="100%" height="600"></iframe>
                            <b>
                                <p innerHTML="Inserted By:-{{item.insertedBy}}"><small>Inserted
                                        By:-{{item.insertedBy}}</small></p>
                            </b>

                        </div>
                    </ng-container>

                </div>
            </div>

        </div>
    </div>
</div>
<!-- News With Sidebar End -->