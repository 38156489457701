import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-website-pdf',
  templateUrl: './website-pdf.component.html',
  styleUrls: ['./website-pdf.component.css']
})
export class WebsitePdfComponent implements OnInit {
  Pdfform: FormGroup; 
  dropdownvalue:any;
  SpResult: any;
  photogallery: any;
  ImageResult: any;
  str: string; 
  ImageResult1: string;
  Token: string = localStorage.getItem('auth_token');
  DropdownLIst: any;
  data:any;
  dataa:any;
  public uploader: FileUploader = new FileUploader
  ({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter:
   { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":2}' } });
   constructor(private fb: FormBuilder, private mainService: MainService, private dom:DomSanitizer) { }

  ngOnInit(): void {
    this.Pdfform = this.fb.group({
      pdfID: [''],
      pdfPath: [''],
      insertedDate: [''],
      insertedBy: [''],
      PdfTitle:[''],
      Pdfcategory:['']
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.Pdfform.value.FileName = this.ImageResult1;

   }
}
async Insert() {
    const MoObj: any = {};
    MoObj.Pid = 'cbb589c1-7dfe-4989-b636-0db4a7239492';
    MoObj.InsertedBy = this.Pdfform.value.insertedBy;
    MoObj.Description= this.Pdfform.value.PdfTitle;
    localStorage.setItem('pdfpath',MoObj.Description);
    this.data=localStorage.getItem('pdfpath');
    if(this.data!==""){
      this.dataa = this.uploader.uploadAll();
      let handlePromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.dataa);
        }, 5000)
      })
      await handlePromise
      MoObj.PDFPath = this.Pdfform.value.FileName;
      MoObj.Category= this.dropdownvalue;
     }
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  Delete() {
    const MoObj: any = {};
    MoObj.Pid = '4471b9fb-7793-4574-8f21-518ce0a28411';
    MoObj.PkWebSite_PDFPath = this.Pdfform.value.pdfID;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table;
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }

  List() {
    const MoObj: any = {};
    MoObj.Pid = '1a76f18b-ae9c-4983-b8f3-92ae7ca3fbd8';
    MoObj.PkWebSite_PDFPath = 0;
    this.mainService.OneMethod(MoObj).then(value => {
      this.photogallery = value;
      this.photogallery = this.photogallery.table1;
    
    });
  }
 RowSelected(u: any) {
    this.Pdfform.controls['pdfID'].setValue(u.pkWebSite_PDFPath);
    this.Pdfform.controls['pdfPath'].setValue(u.pdfPath);
    this.Pdfform.controls['insertedDate'].setValue(u.insertedDate);
    this.Pdfform.controls['insertedBy'].setValue(u.insertedBy);
    this.Pdfform.controls['PdfTitle'].setValue(u.description);
    this.Pdfform.controls['Pdfcategory'].setValue(u.category);
    window.scroll({
      top: 0
    });
  }
  getEmbedUrl(img2)
  {
    return this.dom.bypassSecurityTrustResourceUrl(img2);
   }
   Reset(){
    this.Pdfform.reset();
  }
  navigateTo(value) {
    this.dropdownvalue=value;
  }
}
