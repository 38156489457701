<div class="container-fluid bg-dark py-3 mb-3">
    <div class="container">
        <div class="row align-items-center bg-dark">
            <div class="col-12">
                <div class="d-flex justify-content-between">
                    <div class="bg-primary text-dark text-center font-weight-medium py-2 BorderRadius"
                        style="width: 170px;">Breaking News</div>
                    <div class=" position-relative d-inline-flex align-items-center ml-3"
                        style="width: calc(100% - 170px); padding-right: 90px;">
                        <marquee onmouseover="this.stop();" onmouseleave="this.start();">
                            <ng-container *ngFor="let item of SpResult">      
                            <a class="text-white text-uppercase font-weight-semi-bold MarqueeTag" style="float:left; margin-right: 15px;" innerHTML="{{item.newsDec}}" routerLink="/news">
                            {{item.newsDec}}</a>
                        </ng-container>
                        </marquee>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>