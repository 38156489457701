import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-youtuvevideos',
  templateUrl: './youtuvevideos.component.html',
  styleUrls: ['./youtuvevideos.component.css']
})
export class YoutuvevideosComponent implements OnInit {
  SpResult: any;
  Dropdownlist: any;
  table: any;
  table2: any;
  list: any = [];
  selectedList: any;
  selectedListvideo: any;
  constructor(private mainservice: MainService, private router: Router, private Domsanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.VidePublicList();
  }
  VidePublicList() {
    const MoObj: any = {};
    MoObj.Pid = 'cb154352-dcc2-4d9f-8d35-be9a65a02111';
    MoObj.FkWebSite_VideoGalleryHeader = 0;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.table = this.SpResult.table;
      this.table2 = this.SpResult.table1;
      for (let i = 0; i < this.table.length; i++) {
        let pk = this.table[i].pkWebSite_VideoGalleryHeader;
        for (let j = 0; j < this.table2.length; j++) {
          let fk = this.table2[j].fkWebSite_VideoGalleryHeader;
          let video = this.table2[j].videoPath;
          if (pk == fk) {
            this.table2[j]['vid'] = video;
            this.list.push(this.table2[j]);
          }
        }
      }
    });
  }
  getEmbedUrl(img2) {
    return this.Domsanitizer.bypassSecurityTrustResourceUrl(img2);
  }
}
