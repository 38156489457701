import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.css']
})
export class ClientLogoComponent implements OnInit {
  Token: string = localStorage.getItem('auth_token');
  str: string;
  ImageResult1: string;
  Clientlogoform: FormGroup;
  SpResult: any;
  @ViewChild('fileInput') fileInput;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"1007","PkSystemUploadDocSetting":1}' } });
  constructor(private fb: FormBuilder, private mainservice: MainService) { }
  ngOnInit(): void {
    this.Clientlogoform = this.fb.group({
      ImageId: [''],
      ImageName: [''],
      UrlPath: [''],
    });
    //file Uploader
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
     
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.Clientlogoform.value.FileName = this.ImageResult1;
      this.Clientlogoform.controls['UrlPath'].setValue(this.ImageResult1);
    }
    //End file Uploader
  }
  Insert() {
    var MoObj: any = {}
    MoObj.Pid = "217c07f0-8931-4101-8613-c24f8aac954a";
    MoObj.ImgPath = this.Clientlogoform.value.UrlPath;
    MoObj.Name = this.Clientlogoform.value.ImageName;
    MoObj.UrlPath = this.Clientlogoform.value.UrlPath;
    this.mainservice.OneMethod(MoObj).then(value => {
    this.SpResult = value;
    alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.Reset();
  }
  List() {
    var MoObj: any = {}
    MoObj.Pid = "6e88974d-f821-416d-b34e-ef5948fcec32";
    MoObj.Pk_WebSite_ClientLogoImg = 0;
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table
    });
  }

  Delete() {
    var MoObj: any = {}
    MoObj.Pid = "787c1b02-bfa0-4dc2-bc82-a97aa1503a7b";
    MoObj.Pk_WebSite_ClientLogoImg = this.Clientlogoform.value.ImageId;
    this.mainservice.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table
      alert(JSON.stringify(this.SpResult.table[0].spResult));
    });
    this.List();
  }
  RowSelected(u: any) {
    this.Clientlogoform.controls['ImageId'].setValue(u.pk_WebSite_ClientLogoImg);
    this.Clientlogoform.controls['ImageName'].setValue(u.name);
    this.Clientlogoform.controls['UrlPath'].setValue(u.urlPath);
    this.Clientlogoform.controls['ImagePath'].setValue(u.imgPath);

  }
  Reset(){
    this.Clientlogoform.reset();
  }

}
