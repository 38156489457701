import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
  Dropdownlist: any;
  SpResult: any;
  table: any;
  table2: any;
  list: any = [];
  selectedList: any;
  imgpath: any;
  selectedListimg: any;
  selectedswitchlist:any=0;
  constructor(private mainservice: MainService) { }
  ngOnInit(): void {
    this.PhotoPublicList();
  }
  PhotoPublicList() {
    var MoObj: any = {}
    MoObj.Pid = "a2f71d3f-9b36-46cc-963d-86e09b4f63ec";
    MoObj.PkWebSite_PhotoGallery = 0;
    this.mainservice.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.table = this.SpResult.table;
      this.table2 = this.SpResult.table1;
      for (let i = 0; i < this.table.length; i++) {
        let pk = this.table[i].pkWebSite_PhotoGalleryHeader;
        for (let j = 0; j < this.table2.length; j++) {
          let fk = this.table2[j].fkWebSite_PhotoGalleryHeder;
          let photo = this.table2[j].folderPath;
          let imgpath = this.table2[j].photoPath;
          if (pk == fk) {
            this.table2[j]['photopath'] = imgpath;
            this.table2[j]['images'] = photo;
            this.list.push(this.table2[j]);
          }
        }
      }
    });
 
  }
  openlist(m: any) {
    this.selectedList = m
    $("#containerAll").hide();
  }
  openlistt(n) {
    this.selectedListimg = n
  }
}
