<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">Video Gallery Header</p>
                <form [formGroup]="Galleryheadingform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">Video Header ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="GalleryHeaderID">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Video Header:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="GalleryHeader">
                    </div>

                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="Update" id="login" disabled class="btn btn-sm mb-4 category-btn" type="button"
                        value="Update">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>ID</th>
                                    <th>Video Header</th>

                                </thead>
                                <tbody *ngFor="let video of SpResult">
                                    <tr>
                                        <td (click)="RowSelected(video)" onClick="window.scrollTo(0,0)">
                                            {{video.pkWebSite_VideoGalleryHeader}}</td>
                                        <td>{{video.headerDec}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>