<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-bg">
        <form [formGroup]="employeeForm">
          <h2 class="text-center">Event</h2>
          <br>
          <div class="form-group">
            <label for="uname"><b>Event ID</b></label>
            <input type="text" class="form-control" placeholder="ImageId" formControlName="EventId">
          </div>
          <div class="form-group">
            <label for="uname"><b>Event Decription</b></label>
            <ck-editor name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"
              formControlName="EventDec"></ck-editor>
          </div>
          <div class="form-group">
            <label for="uname"><b>Event Photo</b></label>
            <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader" id="fileInput"
              formControlName="EventPic" />
          </div>
          <div class="form-group">
            <label for="uname"><b>Image Url</b></label>
            <input type="text" class="form-control" placeholder="UrlPath">
          </div>
          <div class="align-center">
            <button type="submit" class="btn btn-primary category-btn" (click)="Insert()">Insert</button>&nbsp;
            <button type="submit" class="btn btn-primary category-btn"
              (click)="Update()">Update</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary category-btn"
              (click)="Delete()">Delete</button>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-primary category-btn"
              (click)="List()">List</button>&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </form>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="container">
        <div class="table-responsive">
          <h2 class="text-center">Event</h2>
          <br>
          <br>
          <table class="table" id="tblList">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fk</th>
                <th>Path</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cmp of eventImgList;">
                <td (click)="imageDelete(cmp.pkWebSite_EventImg)">{{cmp.pkWebSite_EventImg}}</td>
                <td>{{cmp.fkWebSite_Event}}</td>
                <td><img src="{{cmp.folderPath}}"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<br>
<div class="container">
  <div class="table-responsive">
    <table class="table" id="tblList">
      <tbody *ngFor="let cmp of SpResult;">
        <tr>
          <th>ID</th>
        <tr>
        <tr>
          <td (click)="RowSelected(cmp)">{{cmp.pkWebSite_Event}}</td>
        </tr>
        <tr>
          <th>Decription</th>
        </tr>
        <tr>
          <td innerHTML="{{cmp.eventDec}}">{{cmp.eventDec}}</td>
        </tr>
        <br>
      </tbody>
    </table>
  </div>
</div>