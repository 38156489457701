<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">Client Testimonial</p>
                <form [formGroup]="testimonialform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">Member ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="TesttimonialId">
                    </div>
                    <div class="form-group row">
                        <label for="uname"><b>Testimonial Image:</b></label>
                        <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader"
                            (change)="uploader.uploadAll()" id="fileInput" />

                    </div>
                    <div class="form-group">
                        <label for="uname"><b>Image Url:</b></label>
                        <input type="text" class="form-control" placeholder="ImagePath" formControlName="ImagePath">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Client Name:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="Clientname">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Designation:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="Designation">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Decription:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="Decription">
                    </div>
                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="Update" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Update"
                        (click)="Update()">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">

                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">

                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>Id</th>
                                    <th>Image Path</th>
                                    <th>clientName</th>
                                    <th>designation</th>
                                    <th>Decription</th>

                                </thead>
                                <tbody *ngFor="let testimonial of SpResult">
                                    <tr>
                                        <td (click)="RowSelected(testimonial)" onClick="window.scrollTo(0,0)">
                                            {{testimonial.pk_WebSite_Testimonial}}</td>
                                        <td>
                                            <img src="{{testimonial.folderPath}}">
                                        </td>
                                        <td>{{testimonial.clientName}}</td>
                                        <td>{{testimonial.designation}}</td>
                                        <td innerHTML="{{testimonial.testimonialDec}}">{{testimonial.testimonialDec}}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>