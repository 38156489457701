import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminModule } from './admin/admin.module';
import { FileUploadModule } from 'ng2-file-upload';
import { CKEditorModule } from 'ngx-ckeditor';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { ContactComponent } from './contact/contact.component';
import { LayoutComponent } from './layout/layout.component';
import { FeaturedNewsComponent } from './featured-news/featured-news.component';
import { HerosectionComponent } from './herosection/herosection.component';
import { BreakingnewsComponent } from './breakingnews/breakingnews.component';
import { LatestnewsComponent } from './latestnews/latestnews.component';
import { FollowlinkComponent } from './followlink/followlink.component';
import { YoutuvevideosComponent } from './youtuvevideos/youtuvevideos.component';
import { AboutComponent } from './about/about.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { RegistrationComponent } from './registration/registration.component';
import { NewsComponent } from './news/news.component';
import { AchievementComponent } from './achievement/achievement.component';
import { VideoComponent } from './video/video.component';
import { PhotoComponent } from './photo/photo.component';
import { CommitteesComponent } from './committees/committees.component';
import { NewspaperComponent } from './newspaper/newspaper.component';
import { LoginComponent } from './login/login.component';
import { ClientLogoComponent } from './client-logo/client-logo.component';
import { AuthGuard } from './services/auth-guard.service';
import { AuthrizationService } from './services/auth.service';
import { MainService } from './services/main.service';
import { ApiConfigService } from './services/api-config.service';
import { EventComponent } from './event/event.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    MainComponent,
    ContactComponent,
    LayoutComponent,
    FeaturedNewsComponent,
    HerosectionComponent,
    BreakingnewsComponent,
    LatestnewsComponent,
    FollowlinkComponent,
    YoutuvevideosComponent,
    AboutComponent,
    OurteamComponent,
    RegistrationComponent,
    NewsComponent,
    AchievementComponent,
    VideoComponent,
    PhotoComponent,
    CommitteesComponent,
    NewspaperComponent,
    LoginComponent,
    ClientLogoComponent,
   EventComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    AppRoutingModule,
    HttpClientModule,
    AdminModule,
    CKEditorModule,
    HttpModule,
    NgbModule,
    NgxPaginationModule
],
  providers: [    
    AuthrizationService,
    AuthGuard,
    MainService,
    ApiConfigService  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
