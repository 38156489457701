<app-herosection></app-herosection>
<app-breakingnews></app-breakingnews>
<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <app-youtuvevideos></app-youtuvevideos>
                <app-latestnews></app-latestnews>
            </div>
            <div class="col-lg-4">
                <app-followlink></app-followlink>
                <div class="mb-3">
                    <div class="section-title mb-0">
                        <h4 class="m-0 text-uppercase font-weight-bold">NEWS & EVENTS </h4>
                    </div>
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/kovindd.jpg" alt=""></a>
                     <p>With President of India</p>  
                     <b><p style="margin-top: -20px;">Mr. Ram Nath Kovind</p></b> 
                    </div>
                </div>
                <div class="mb-3">
                    
                    <div class="bg-white text-center border border-top-0 p-3">
                        <a href=""><img class="img-fluid" src="assets/img/MD1.jpg" alt=""></a>
                     <b><p>Message From Founder</p></b>
                     <p style="text-align: justify;">"I strongly believe that the youth of our country 
                        enable us to hope for a prosperous future. The aware citizens form the building 
                        block of a progressive nation. It is quintessential for every individual to be 
                        informed of their fundamental rights alongwith the knowledge of our constitution. 
                        It is therefore our promise and commitment to serve the people of the nation to the 
                        best of their interest."</p>
                        <b><p>Dr. Milind Dahiwale</p></b>
                        <p>Founder</p>
                        <p style="margin-top: -20px;">+(91) 9552011005</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>