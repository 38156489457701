import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  admin:any=localStorage.getItem('email');
  constructor() { }
  ngOnInit(): void {
    $(document).ready(function () {
      $("#navlink").click(function () {
        $("#ui-basic").toggle();
      });
    });
  }
}
