<div class="container">
    <div class="row">
        <div class="col-md-7 maindiv">
            <div class="card-body" style="text-align: left;">
                <p class="login-card-description">Photo Gallery</p>
                <form [formGroup]="Photogalleryform">
                    <div class="form-group">
                        <label for="formGroupExampleInput">Image ID:</label>
                        <input type="text" class="form-control" disabled name="ImageId" id="ImageId"
                            placeholder="ImageId" formControlName="GalleryID">
                    </div>
                    <div class="form-group">
                        <label for="sel1">Gallery Header:</label>
                        <select class="form-control" id="sel1" formControlName="GalleryHeader">
                            <option value="" selected>Please Select</option>
                            <option *ngFor="let item of DropdownLIst" value="{{item.pkWebSite_PhotoGalleryHeader}}">
                                {{item.headerDec}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Gallery Heading :</label>
                        <input type="text" class="form-control" name="ImageId" id="ImageId" placeholder="ImageId"
                            formControlName="GalleryHeading">
                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput">Image Name:</label>
                        <input type="text" class="form-control" placeholder="ImageName" formControlName="ImageName">
                    </div>
                    <div class="form-group row">
                        <label for="formGroupExampleInput">File Upload:</label>
                        <input type="file" name="imageUpload" ng2FileSelect [uploader]="uploader"
                         id="fileInput" formControlName="ImagePath" />
                    </div>
                    <hr>
                    <input name="Insert" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Insert"
                        (click)="Insert()">
                    <input name="List" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="List"
                        (click)="List()">
                    <input name="Delete" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Delete"
                        (click)="Delete()">
                    <input name="Update" id="login" disabled class="btn btn-sm mb-4 category-btn" type="button"
                        value="Update">
                    <input name="Exist" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Exist">
                    <input name="Cancel" id="login" class="btn btn-sm mb-4 category-btn" type="button" value="Cancel">
                </form>
            </div>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row">
            <div class="col-md-10" style="margin:auto; ">
                <div class="card" style="width:100%; ">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="text-primary">
                                    <th>
                                        Id
                                    </th>
                                    <th>
                                        Gallery Header
                                    </th>
                                    <th>
                                        Image Name
                                    </th>
                                    <th>
                                        Image Path
                                    </th>

                                </thead>
                                <tbody *ngFor="let imgName of photogallery">
                                    <tr>
                                        <td (click)="RowSelected(imgName)" onClick="window.scrollTo(0,0)">
                                            {{imgName.pkWebSite_PhotoGallery}}
                                        </td>

                                        <td>
                                            {{imgName.fkWebSite_PhotoGalleryHeder}}
                                        </td>
                                        <td>
                                            {{imgName.photoDec}}
                                        </td>
                                        <td>
                                            <img src="{{imgName.folderPath}}">
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>